<template>
  <li
    v-if="!currentUploadImage"
    @click=" isMobile && !isSpecialDeviceBrowser ? initImgUpload() : initImgUploadFileOptional()"
    class="upload-item-optional photo-list-optional"
    :class="[scalable ? 'upload-item-photos font-weight-thin' : '',uploadImageOptionalLengthCheck === 0 ? 'no-item-optional' : uploadImageOptionalLengthCheck === 1 ? 'first-item-optional' : '']"
    style="position: relative;"
    :style="{
      filter: authenticateData.listed ? 'grayscale(100%)' : 'none',
      cursor: authenticateData.listed ? 'default!important' : 'pointer',
      'background-image':
        !frameKey.includes('interiorOther') ?
        'url(' +
        require(`../../assets/upload-guide/${frame}`) +
        ')' : 'none',
      'background-size': scaleList.includes(frameKey) ? '65px' : frameKey === 'exteriorFront' ? '75px' : scalable && !scaleList.includes(frameKey) ? '130px' : '25px !important',
    }"
  >
    <img :src="theme.otherPhotoUploadIcons" :class="[frameKey.includes('interiorOther') ? 'optional-other-icon' : 'add-icon']"/>
    <div
      class="image-input-wrapper"
      style="width: 100%; height: 100%"
    >
      <input
        :ref="frameKey"
        type="file"
        :id="frameKey"
        :name="frameKey"
        style="display: none"
        multiple
        @change="uploadPhoto"
        accept="image/*"
      />
      <div
        class="cover-text-wrapper"
        style="
          position: absolute;
          top: 72%;
          left: 50%;
          margin: 0 !important;
          padding: 0 !important;
          transform: translate(-50%, -50%);
          width: 100%;
        "
      >
        <div class="frame-title fs-10 font-weight-thin text-white">
          {{ title }}
        </div>
        <button type="button" class="photo-text">
          {{
            isMobile && !isSpecialDeviceBrowser ? "ADD/TAKE PHOTOS" : "ADD PHOTOS"
          }}
        </button>
        <div class="fs-14 cover-description-text font-weight-thin">
          Supports: jpeg, png, tiff
        </div>
      </div>
    </div>
  </li>
  <li v-else class="frame-item">
    <b-overlay
      :show="currentUploadImage.onUpload"
      no-wrap
      spinner-variant="success"
      :opacity="0.5"
    ></b-overlay>
    <img
      :src="currentUploadImage.url"
      width="100%"
      class="uploaded-image"
      :class="currentUploadImage.err ? 'img-upload-fail' : ''"
      :draggable="false"
    />
    <div v-if="currentUploadImage.err" class="upload-fail-text d-flex align-items-center">
      <img src="@/assets/images/icons/ic_upload_fail.svg" class="upload-fail-icon" alt="">
      <p class="font-weight-thin">Image upload failed</p>
    </div>
    <b-progress
      v-if="currentUploadImage.progress && currentUploadImage.progress !== 100"
      class="img-upload-progress"
      :value="currentUploadImage.progress"
      variant="success"
      striped
    ></b-progress>
    <div class="edit-options-list" v-show="!currentUploadImage.onUpload" v-if="!currentUploadImage.err">
      <v-touch v-on:tap="rotateImg">
        <custom-icon
          name="roll"
          class="edit-icon"
          width="100%"
          @click="rotateImg"
        />
      </v-touch>
      <v-touch v-on:tap="deleteImg">
        <custom-icon
          name="delete"
          class="edit-icon"
          width="100%"
          :m0="true"
          @click="deleteImg"
        />
      </v-touch>
    </div>
  </li>
</template>
<script>
import { mapState } from "vuex";
import { optionalList } from '@/config/constants';
import { socialNetworkBrowser, browserList } from "@/helper/constant";

export default {
  name: "FrameItemOptional",
  data() {
    return {
      isMobile: true,
      isSpecialDeviceBrowser: false,
      optionalList,
      scaleList: ['interiorOwnerManual', 'interiorServiceHistory'],
    };
  },
  props: {
    uploadImageLength: {
      type: Number,
      default: 0
    },
    uploadImageOptionalLengthCheck: {
      type: Number,
      default: 0
    },
    frame: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    scalable: {
      type: Boolean,
      default: () => false,
    },
    frameKey: {
      type: String,
      default: () => "",
    },
    uploadImages: {
      type: Array,
      default: () => [],
    },
  },
  mounted () {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
    if (this.isMobile) {
      const userAgent = navigator.userAgent || window.opera; // Check user use in-app facebook or instagram browser android so can only upload
      const isAndroidSpecialBrowser = userAgent.includes('Android') && socialNetworkBrowser.some(browser => userAgent.includes(browser));
      const isiOSChromeBrowser = (userAgent.includes('iPhone') || userAgent.includes('iPad')) && userAgent.includes(browserList);
      
      if (isAndroidSpecialBrowser) {
        this.isSpecialDeviceBrowser = true;
        this.isDialogPhotoUploadIssueOpen = true;
      } else if (isiOSChromeBrowser) {
        this.isSpecialDeviceBrowser = true;
      }
    }
  },
  methods: {
    initImgUploadFileOptional() {
      this.$refs[this.frameKey].click();
    },
    initImgUpload() {
      this.$emit("initImgUpload");
      this.$store.dispatch('setKeyFrame', this.frameKey);
    },
    uploadPhoto: function (event) {
      var input = event.target;
      if (input.files.length > 0) {
        this.$emit("uploadPhoto", input.files[0], this.frameKey, this.title);
      }
    },
    deleteImg() {
      this.$emit(
        "deleteImg",
        this.currentUploadImage.filename,
        this.currentUploadImage.key
      );
    },
    rotateImg() {
      this.$emit("rotateImg", this.currentUploadImage);
    },
  },
  computed: {
    ...mapState({
      authenticateData: (state) => state.authenticateSeller,
      theme: (state) => state.commonVariables.theme,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 900px)");
    },
    currentUploadImage() {
      return this.uploadImages.find((item) => item.key === this.frameKey);
    },
  },
  components: {
    CustomIcon: () => import("../common/CustomIcon"),
  },
};
</script>
<style lang="scss">
.upload-item-optional {
  position: relative;
  background-color: #5E5E5E;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2rem;
  cursor: pointer !important;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
  .photo-text {
    display: none;
  }
  .cover-text {
    display: none;
  }
  .cover-description-text {
    display: none;
  }
  .frame-title {
    padding-top: 2.5rem;
    @media screen and (min-width: 600px ) and (max-width: 767px) {
      padding-top: 5rem;
    }
    @media screen and (min-width: 768px ) and (max-width: 900px ) and (orientation: portrait) {
      padding-top: 6.5rem;
    }
    @media screen and (min-width: 800px ) and (max-width: 900px) and (orientation: landscape) {
      padding-top: 7rem;
    }
  }
  .optional-other-icon {
    position: absolute;
    height: 26px !important;
    width: 26px !important;
  }
  .add-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px !important;
    display: grid;
    place-items: center;
  }
}

.frame-item {
  width: 100%;
  height: calc(30vw - 0.5rem);
  min-height: 109px;
  background-color: #5e5e5e;
  font-size: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .ghost-image {
    width: 45%;
    transform: translateY(-3px);
    &.scalable {
      width: 70%;
    }
  }
  .frame-name {
    position: absolute;
    bottom: 3px;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    .super {
      vertical-align: super;
      font-size: 6px;
    }
  }

  .uploaded-image {
    height: 100%;
    object-fit: cover;
  }

  .edit-options-list {
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
  .img-upload-fail {
    background-color: #eee;
  }
  .upload-fail-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    justify-content: center;
    .upload-fail-icon {
      width: 14px !important;
      height: 14px !important;
    }
    p {
      color: #D20319 !important;
      font-size: 11px !important;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
}

@media screen and(min-width: 768px) {
  .frame-item {
    min-width: 150px;
  }
}

@media screen and(min-width: 900px) {
  .frame-item {
    height: 100%;

    .edit-icon {
      width: 18px !important;
      height: 18px !important;
    }

    .uploaded-image {
      height: 109px;
    }
  }
}
</style>
